<template>
  <footer class="footer">
    <div class="footer-top container">
      <!-- Logo -->
      <a href="/" class="footer__logo">
        <magnum-logo></magnum-logo>
      </a>

      <!-- Menu -->
      <div class="footer__menu footer-menu">
        <ul class="footer-menu__list">
          <li class="footer-menu__item">
            <locale-router-link to="/advertisers" class="footer-menu__link">{{
              $t("advertisers")
            }}</locale-router-link>
          </li>
          <li class="footer-menu__item">
            <locale-router-link to="/landlords" class="footer-menu__link">{{
              $t("landlordsOwners")
            }}</locale-router-link>
          </li>
          <li class="footer-menu__item">
            <locale-router-link to="/magnum-opt" class="footer-menu__link">{{
              $t("magnumOpt")
            }}</locale-router-link>
          </li>

          <li class="footer-menu__item">
            <a
              href="https://magnum.kz"
              target="_blank"
              class="footer-menu__link"
              >Magnum.kz</a
            >
          </li>
        </ul>
        <ul class="footer-menu__list">
          <li class="footer-menu__item">
            <locale-router-link to="/suppliers" class="footer-menu__link">{{
              $t("suppliersCorp")
            }}</locale-router-link>
          </li>
          <li class="footer-menu__item">
            <locale-router-link to="/tenants" class="footer-menu__link">{{
              $t("forTenants")
            }}</locale-router-link>
          </li>

          <li class="footer-menu__item">
            <locale-router-link to="/bidding" class="footer-menu__link">{{
              $t("tradeMagnum")
            }}</locale-router-link>
          </li>

          <li class="footer-menu__item">
            <locale-router-link
              to="/compliance-service"
              class="footer-menu__link"
              >{{ $t("complianceService") }}</locale-router-link
            >
          </li>
        </ul>
      </div>

      <!-- Info -->
      <div class="footer__info footer-info">
        <div class="footer-info__support">{{ $t("support") }}</div>
        <div class="footer-info__number">
          <a href="tel:7766" class="support-number">
            <span>{{ $t("callCenter") }}</span></a
          >
        </div>
        <div class="footer-info__info">{{ $t("socials") }}</div>
        <div v-if="social" class="footer-info__social">
          <a :href="social.facebook_link" target="_blank">
            <img src="@/assets/img/icons/facebook.svg" alt="" />
          </a>
          <a :href="social['IG']" target="_blank">
            <img src="@/assets/img/icons/insta.svg" alt="" />
          </a>
          <a :href="social.twitter" target="_blank">
            <img src="@/assets/img/icons/twitter.svg" alt="" />
          </a>
          <a :href="social.tiktok" target="_blank">
            <img src="@/assets/img/icons/tiktok.svg" alt="" />
          </a>
          <a :href="social.telegram" target="_blank">
            <img src="@/assets/img/icons/telegram.svg" alt="" />
          </a>
          <a :href="social.vk" target="_blank">
            <img src="@/assets/img/icons/vk.svg" alt="" />
          </a>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="container">
        <span>Magnum Cash & Carry @2022 Все права защищены</span>
      </div>
    </div>
  </footer>
</template>

<script>
import { getSocialLink } from "@/api/social-link";
export default {
  name: "AppFooter",
  components: {
    MagnumLogo: () => import("@/ui/MagnumLogo.vue"),
  },
  data() {
    return {
      social: null,
    };
  },
  created() {
    getSocialLink().then((res) => {
      this.social = res.attributes;
    });
  },
};
</script>
